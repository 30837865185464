document.addEventListener('click', function (x) {
	return 0;
});

var fixScroll;
var oldMode;

window.addEventListener('load', AOS.refresh);

$(document).ready(function () {
	var body = $('body');

	body.append('<div class="set-mode"></div>');
	oldMode = $('.set-mode').css('z-index');
	setMode();

	if (checkTouchDevice()) {
		body.addClass('touchscreen');
	}

	header();

	flying();

	AOS.init({
		startEvent: 'load',
		once: true,
	});

	AOS.refresh();

	if (!checkTouchDevice()) {
		$('.scrollbar-inner').scrollbar();
	}

	$(window).scroll();

	$('.logos-carousel').logos();

	$('.reviews-carousel .owl-carousel').owlCarousel({
		items: 1,
		loop: false,
		margin: 40,
		navText: [],
		autoHeight: true,
		navContainer: '.reviews-nav .carousel-nav',
		responsive: {
			0: {
				nav: false,
				dots: true,
				margin: 16,
			},
			768: {
				nav: true,
				dots: false,
				margin: 40,
			},
		},
	});

	$('.logos-carousel .owl-carousel').owlCarousel({
		items: 1,
		margin: 30,
		loop: true,
		nav: false,
		dots: false,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		freeDrag: false,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		autoplay: true,
		autoplayTimeout: 5000,
	});

	$('.industry-carousel .owl-carousel').owlCarousel({
		navText: [],
		navContainer: '.industry .carousel-nav',
		responsive: {
			0: {
				nav: false,
				dots: true,
				autoWidth: false,
				items: 1,
				loop: true,
				margin: 16,
			},
			480: {
				nav: false,
				dots: true,
				autoWidth: false,
				items: 2,
				loop: true,
				margin: 16,
			},
			768: {
				nav: true,
				dots: false,
				autoWidth: true,
				items: 3,
				loop: false,
				margin: 32,
			},
		},
	});

	$('.section-text-slider .owl-carousel').owlCarousel({
		navText: [],
		navContainer: '.section-text-slider .carousel-nav',
		autoWidth: true,
		loop: true,
		margin: 32,
		responsive: {
			0: {
				items: 1,
				autoWidth: false,
				nav: false,
				dots: true,
			},
			767: {
				autoWidth: true,
				nav: true,
				dots: false,
			},
		},
	});

	$('.section-requirements__slider').owlCarousel({
		navText: [],
		navContainer: '.section-requirements__slider .carousel-nav',
		margin: 32,
		items: 1,
	});

	$('.section-savings__slider .owl-carousel').owlCarousel({
		navText: [],
		navContainer: '.section-savings__slider .carousel-nav',
		margin: 32,
		center: true,
		responsive: {
			0: {
				items: 1,
				nav: false,
				dots: true,
			},
			991: {
				items: 1.5,
				nav: true,
				dots: false,
			},
			1280: {
				items: 2,
				nav: true,
				dots: false,
			},
		},
	});

	if (window.innerWidth > 991) {
		$(".section-savings__slider .owl-carousel").trigger("to.owl.carousel", [1, 1, true]);
	}

	$('.card-carousel .owl-carousel').owlCarousel({
		loop: false,
		nav: false,
		dots: true,
		responsive: {
			0: {
				items: 1,
				margin: 16,
			},
			480: {
				items: 2,
				margin: 16,
			},
			768: {
				items: 3,
				loop: true,
				margin: 16,
			},
			1000: {
				items: 4,
				margin: 24,
			},
		},
	});

	$('.card-carousel-2 .owl-carousel').owlCarousel({
		loop: false,
		responsive: {
			0: {
				items: 1,
				margin: 16,
				nav: false,
				dots: true,
			},
			480: {
				items: 2,
				margin: 16,
				nav: false,
				dots: true,
			},
			768: {
				items: 3,
				loop: true,
				margin: 16,
				nav: false,
				dots: true,
			},
			1024: {
				items: 4,
				margin: 24,
				nav: true,
				dots: false,
			},
		},
	});

	$('.reviews-special-carousel .owl-carousel').owlCarousel({
		navText: [],
		navContainer: '.reviews-special .carousel-nav',
		responsive: {
			0: {
				nav: false,
				dots: true,
				autoWidth: false,
				items: 1,
				loop: true,
				margin: 16,
			},
			540: {
				nav: false,
				dots: true,
				autoWidth: false,
				items: 2,
				loop: true,
				margin: 16,
			},
			768: {
				nav: true,
				dots: false,
				autoWidth: true,
				items: 3,
				loop: false,
				margin: 32,
			},
		},
	});

	$('.section-guard__slider .owl-carousel').owlCarousel({
		navText: [],
		navContainer: '.section-guard__slider .carousel-nav',
		margin: 24,
		responsive: {
			0: {
				items: 1,
				dots: true,
			},
			768: {
				items: 2,
				dots: true,
			},
			1024: {
				items: 3,
				dots: false,
			},
			1025: {
				margin: 32,
			}
		},
	});

	$('.section-update__slider .owl-carousel').owlCarousel({
		navText: [],
		navContainer: '.section-update__slider .carousel-nav',
		mouseDrag: false,
		responsive: {
			0: {
				items: 1,
				dots: true,
			},
			768: {
				items: 2,
				dots: true,
				margin: 32,
			}
		},
		onInitialized: () => {
			const cardUpdateAll = document.querySelectorAll('.section-update__item');

			cardUpdateAll.forEach(cardUpdate => {
				cardUpdate.addEventListener('click', function () {
					this.classList.toggle('active');
				})
			});
		}
	});

	$('.nav-button').click(function () {
		if (!body.hasClass('is-locked')) {
			fixScroll = $(window).scrollTop();
			body.addClass('is-locked').css('top', -1 * fixScroll);
		} else {
			body.removeClass('is-locked').removeAttr('style');
			$(window).scrollTop(fixScroll);
			header();
		}
	});

	$('.video-play').click(function () {
		var box = $(this).closest('.video');
		var iframe = box.find('iframe');
		iframe.attr('src', iframe.attr('data-src'));
		box.addClass('is-loaded');
		$(this).remove();
	});

	$('.show-more-link').click(function () {
		var parent = $(this).parent();
		var destination;
		if (parent.hasClass('is-opened')) {
			parent.removeClass('is-opened');
			var target;
			if (parent.closest('.tab').length) {
				target = parent.closest('.tab');
			} else {
				target = parent.parent();
			}
			destination = target.offset().top - $('header').height();
		} else {
			parent.addClass('is-opened');
			destination = parent.offset().top - $('header').height();
		}
		$(window).scrollTop(destination);
		flying();
		return false;
	});

	$('.show-more-link-two').click(function () {
		var parent = $(this).parent();
		var destination;
		if (parent.hasClass('is-opened')) {
			parent.removeClass('is-opened');
			var target;
			if (parent.closest('.tab').length) {
				target = parent.closest('.tab');
			} else {
				target = parent.parent();
			}
		} else {
			parent.addClass('is-opened');
		}
		flying();
		return false;
	});

	$('[data-tab]').click(function (event, noScroll) {
		if (!$(this).hasClass('is-active')) {
			$('[data-tab].is-active, .tab.is-active').removeClass('is-active');
			$('[data-tab="' + $(this).attr('data-tab') + '"]').addClass('is-active');
			$('.tab' + $(this).attr('data-tab'))
				.addClass('is-active')
				.removeClass('is-closed');
			AOS.refresh();
		} else {
			$('.tab' + $(this).attr('data-tab')).toggleClass('is-closed');
		}
		if ($(this).closest('.tabs').length && window.matchMedia('(min-width: 1000px)').matches && !noScroll) {
			var destination = $(this).closest('.content').offset().top - $('header').height();
			$('html,body')
				.stop()
				.animate(
					{
						scrollTop: destination,
					},
					300,
					function () {
						flying();
					}
				);
		}
		if (window.matchMedia('(max-width: 999px)').matches && !$(this).closest('.tabs-nav').hasClass('no-scroll')) {
			var destination = $(this).closest('.tab').offset().top - $('header').height();
			$('html,body').stop().animate(
				{
					scrollTop: destination,
				},
				300
			);
		}
		return false;
	});

	$('.partners-more').click(function () {
		var destination = $(window).scrollTop();
		$('.partners-box.is-hidden').removeClass('is-hidden');
		$(this).hide();
		$(window).scrollTop(destination);
	});

	filterGo();

	$('[data-filter-nav]').click(function () {
		if (!$(this).hasClass('is-active')) {
			$('[data-filter-nav].is-active').removeClass('is-active');
			$(this).addClass('is-active');
			filterGo();
		}
	});

	$('[data-fancybox]').fancybox({
		buttons: ['close'],
		afterShow: function (instance, current) {
			var button = current.opts.$orig;
			if ($(button).hasClass('open-link')) {
				window.location = button.attr('href');
			}
		},
	});

	new Swiper('.intro-homepage', {
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: true,
		},
		navigation: {
			nextEl: '.intro-homepage__control-next',
			prevEl: '.intro-homepage__control-prev',
		},
		pagination: {
			el: '.intro-homepage__pagination'
		}
	})
});

$(window).scroll(function () {
	header();

	flying();
});

$(window).resize(function () {
	header();

	flying();

	var newMode = $('.set-mode').css('z-index');
	if (newMode != oldMode) {
		oldMode = newMode;
		setMode();
	}
});

function header() {
	$(window).scrollTop() > 0 ? $('body').addClass('is-scrolled') : $('body').removeClass('is-scrolled');
}

function checkTouchDevice() {
	return 'ontouchstart' in document.documentElement;
}

function setMode() {
	var mode = $('.set-mode').css('z-index');

	var products = $('.products-carousel-box > .owl-carousel');

	var productsList = $('.products-list.owl-carousel');
	var productsListCarousel = [];

	var consult = $('.consult-carousel .owl-carousel');

	var news = $('.news.owl-carousel');

	var cards = $('.card-grid .owl-carousel');

	if (mode > 767) {
		if (!products.hasClass('owl-loaded')) {
			products.owlCarousel({
				items: 1,
				margin: 10,
				nav: false,
				dots: false,
				mouseDrag: false,
				touchDrag: false,
				pullDrag: false,
				freeDrag: false,
			});

			$('.products-tab').on('mouseenter', function () {
				if (!$(this).hasClass('is-active')) {
					$('.products-tabs').find('.is-active').removeClass('is-active');
					products.trigger('to.owl.carousel', [$(this).index(), 300]);
					$(this).addClass('is-active');
				}
			});
		}

		if ($(productsList[0]).hasClass('owl-loaded')) {
			productsList.each(function () {
				$(this).trigger('destroy.owl.carousel');
			});
		}

		if (!consult.hasClass('owl-loaded')) {
			consult.owlCarousel({
				dots: true,
				margin: 32,
				responsive: {
					0: {
						items: 3,
					},
					1000: {
						items: 4,
					},
				},
			});
		}

		if (news.hasClass('owl-loaded')) {
			news.trigger('destroy.owl.carousel');
		}

		if (cards.hasClass('owl-loaded') && cards.parent().hasClass('carousel-mobile-only')) {
			cards.trigger('destroy.owl.carousel');
		}

		if (!cards.hasClass('owl-loaded') && !cards.parent().hasClass('carousel-mobile-only')) {
			cards.owlCarousel({
				loop: true,
				margin: 16,
				nav: false,
				dots: true,
				responsive: {
					0: {
						items: 1,
					},
					480: {
						items: 2,
					},
					768: {
						items: 3,
					},
					1000: {
						items: 4,
					},
				},
			});
		}
	} else {
		if (products.hasClass('owl-loaded')) {
			products.trigger('destroy.owl.carousel');
			$('.products-tab').off('mouseenter');
		}

		if (!$(productsList[0]).hasClass('owl-loaded')) {
			productsList.each(function (i) {
				$(productsList[i]).owlCarousel({
					loop: true,
					margin: 16,
					nav: false,
					dots: true,
					responsive: {
						0: {
							items: 1,
						},
						480: {
							items: 2,
						},
					},
				});
			});
		}

		if (consult.hasClass('owl-loaded')) {
			consult.trigger('destroy.owl.carousel');
		}

		if (!news.hasClass('owl-loaded')) {
			news.owlCarousel({
				loop: true,
				margin: 16,
				nav: false,
				dots: true,
				items: 1,
			});
		}

		if (!cards.hasClass('owl-loaded')) {
			cards.owlCarousel({
				loop: true,
				margin: 16,
				nav: false,
				dots: true,
				responsive: {
					0: {
						items: 1,
					},
					480: {
						items: 2,
					},
				},
			});
		}
	}
}

$.fn.logos = function () {
	return this.each(function () {
		var carousel = $(this);
		var pause = 5000;

		carousel.find('.logos-slide:first').addClass('is-active');

		setHeight();

		$(window).on('load', function () {
			setHeight();
		});

		$(window).resize(function () {
			setHeight();
		});

		setInterval(function () {
			var active = carousel.find('.logos-slide.is-active');

			if (active.next().length) {
				active.next().addClass('is-active');
			} else {
				carousel.find('.logos-slide:first').addClass('is-active');
			}

			active.removeClass('is-active').addClass('is-hiding');

			setTimeout(function () {
				active.removeClass('is-hiding');
			}, pause / 2);
		}, pause);

		function setHeight() {
			var height = 0;

			carousel.find('.logos-slide').each(function () {
				if (height < $(this).height()) {
					height = $(this).height();
				}
			});

			carousel.height(height);
		}
	});
};

function flying() {
	var flying = $('.flying-nav-box');

	if (flying.length) {
		var main = $('.content > article');

		if (main.height() > flying.height()) {
			flying.removeClass('not-flying');

			var scroll = $(window).scrollTop() + $('header').height();

			var delta = main.offset().top + main.outerHeight() - flying.height();

			if (scroll > main.offset().top) {
				if (scroll < delta) {
					flying.addClass('fixed').removeClass('absolute');
				} else {
					flying.removeClass('fixed').addClass('absolute');
				}
			} else {
				flying.removeClass('fixed').removeClass('absolute');
			}
		} else {
			flying.addClass('not-flying');
		}
	}
}

function filterGo() {
	var id = $('[data-filter-nav].is-active').attr('data-filter-nav');
	if (id === 'all') {
		$('[data-filter]').show();
	} else {
		$('[data-filter]').hide();
		$('[data-filter="' + id + '"]').show();
	}
	flying();
	$('.tabs > li:visible:first > [data-tab]').trigger('click', [true]);
}

$(window).on('load', function () {
	var page = parseHref(window.location.href)['page'];

	if (page !== undefined) {
		window.history.scrollRestoration = 'manual';

		if (window.matchMedia('(max-width: 999px)').matches) {
			$('.tab-mobile .tabs-link[data-tab="#' + page + '"]').click();
		} else {
			$('.tabs .tabs-link[data-tab="#' + page + '"]').click();
		}

		var tabsNav = $('.tabs-nav [data-tab="#' + page + '"]');
		if (tabsNav.length) {
			tabsNav.click();
			var tabsNavDestination = tabsNav.offset().top - $('header').outerHeight() - 20;
			$('html,body')
				.stop()
				.animate(
					{
						scrollTop: tabsNavDestination,
					},
					300,
					function () {
						flying();
					}
				);
		}
	}

	var anchor = parseHref(window.location.href)['anchor'];

	if (anchor !== undefined) {
		window.history.scrollRestoration = 'manual';

		var anchor = $('#' + anchor);
		var anchorDestination = anchor.offset().top - $('header').outerHeight() - 20;

		$('html,body')
			.stop()
			.animate(
				{
					scrollTop: anchorDestination,
				},
				300,
				function () {
					flying();
				}
			);
	}
});

function parseHref(h) {
	var iOf = h.indexOf('?');
	var a = h.substring(iOf, h.length).substr(1).split('&');
	if (a == '') return {};
	var b = {};
	for (var i = 0; i < a.length; ++i) {
		var p = a[i].split('=');
		if (p.length != 2) continue;
		b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
	}
	return b;
}

//form-element
const formElement = document.querySelectorAll('.form-element');
formElement.forEach((formElement) => {
	const formElementInput = formElement.querySelector('.form-element__input');
	const formElementClean = formElement.querySelector('.form-element__clean');

	formElementInput.addEventListener('focus', function () {
		if (formElementInput.value.length !== 0) {
			formElement.classList.add('is-clean');
		}
	});

	formElementInput.addEventListener('blur', function () {
		setTimeout(() => {
			formElement.classList.remove('is-clean');
		}, 100);
	});

	formElementInput.addEventListener('input', function () {
		if (formElementInput.value.length !== 0) {
			formElement.classList.add('is-clean');
		} else {
			formElement.classList.remove('is-clean');
		}
	});

	formElementClean.addEventListener('click', function () {
		formElementInput.value = '';
		formElementInput.focus();
	});
});

if (document.querySelector('.partners')) {
	const selectPartnersSettings = {
		searchEnabled: false,
		itemSelectText: '',
		removeItemButton: true,
		allowHTML: false,
		classNames: {
			containerOuter: 'choices select-partners'
		}
	}

	new Choices('#partners-country', selectPartnersSettings);
	new Choices('#partners-type', selectPartnersSettings);

	const selectPartnersCountryDefault = document.getElementById('partners-country');
	const selectPartnersTypeDefault = document.getElementById('partners-type');
	const partnersBoxAll = document.querySelectorAll('.partners-box');

	let sortCountry = '';
	let sortType = '';

	const partnersFilter = () => {
		for (let partnersBox of partnersBoxAll) {
			partnersBox.classList.add('is-hidden');

			if (sortType == '' && sortCountry == '') {
				partnersBox.classList.remove('is-hidden');
			}

			if (sortType == '') {
				if (partnersBox.dataset.country === sortCountry) {
					partnersBox.classList.remove('is-hidden');
				}
			} else if (sortCountry == '') {
				if (partnersBox.dataset.type === sortType) {
					partnersBox.classList.remove('is-hidden');
				}
			} else {
				if (partnersBox.dataset.country === sortCountry && partnersBox.dataset.type === sortType) {
					partnersBox.classList.remove('is-hidden');
				}
			}
		}
	}

	selectPartnersCountryDefault.addEventListener('change', function () {
		console.log(this.value);
		sortCountry = this.value;
		partnersFilter();
	});

	selectPartnersTypeDefault.addEventListener('change', function () {
		console.log(this.value);
		sortType = this.value;
		partnersFilter();
	});
};

// Accordion
const accordionAll = document.querySelectorAll('.accordion');

if (accordionAll.length > 0) {
	for (let accordion of accordionAll) {
		const accordionToggle = accordion.querySelector('.accordion__toggle');
		accordionToggle.addEventListener('click', () => {
			if (accordion.classList.contains('is-active')) {
				accordion.classList.remove('is-active');
			} else {
				closeAccordion();
				accordion.classList.add('is-active');
			}
		})
	}

	const closeAccordion = () => {
		for (let accordion of accordionAll) {
			accordion.classList.remove('is-active');
		}
	}
}

const formSendChat = document.getElementById('form-send-chat');
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
if (formSendChat) {
	formSendChat.addEventListener('submit', (event) => {
		event.preventDefault();

		const dataForm = new URLSearchParams(new FormData(formSendChat));

		const btnSubmit = formSendChat.querySelector('button[type="submit"]');
		const blockSuccessMessage = formSendChat.querySelector('.section-form__success');

		const url = `/submit-form?${dataForm.toString()}`;

        fetch(url, {
            method: 'get',
        })
        .then(res => {
            console.log(res)
        })
        .catch(err => console.error('ERROR:', err))

		btnSubmit.setAttribute('disabled', true);
		sleep(1500)
			.then(() => blockSuccessMessage.classList.remove('hide'))
			.finally(() => btnSubmit.removeAttribute('disabled'))
	})
}



const formFooterMailing = document.querySelector('.footer-mailing');
if (formFooterMailing) {
	formFooterMailing.addEventListener('submit', (event) => {
		event.preventDefault();
		const btnSubmit = formFooterMailing.querySelector('button[type="submit"]');
		const blockSuccessMessage = formFooterMailing.querySelector('.footer-mailing__success');
		btnSubmit.setAttribute('disabled', true);
		sleep(1000)
			.then(() => blockSuccessMessage.classList.remove('hide'))
			.finally(() => btnSubmit.removeAttribute('disabled'))
	})
}

// $(window).setBreakpoints({
// 	distinct: true,
// 	breakpoints: [
// 		768,
// 	]
// });

// $(window).bind('enterBreakpoint768', function () {
// 	$('.photo-gallery').owlCarousel({
// 		navText: [],
// 		navContainer: '.carousel-nav-gallery',
// 		margin: 24,
// 		items: 1,
// 		dots: false,
// 		nav: true
// 	});
// });

// $(window).bind('exitBreakpoint768', function () {
// 	console.log($('.photo-gallery').destroy());
// });

// find element
$owl = $('body').find('.photo-gallery');
// set the owl-carousel otions
var carousel_Settings = {
	navText: [],
	navContainer: '.carousel-nav-gallery',
	margin: 24,
	items: 1,
	dots: false,
	nav: true
};
function initialize() {
	var containerWidth = $('body').outerWidth();
	if (containerWidth <= 767) {
		// initialize owl-carousel if window screensize is less the 767px
		$owl.owlCarousel(carousel_Settings);
	} else {
		// destroy owl-carousel and remove all depending classes if window screensize is bigger then 767px
		$owl.trigger('destroy.owl.carousel').removeClass('owl-loaded');
		$owl.find('.owl-stage-outer').children().unwrap();
	}
}
// initilize after window resize
var id;
$(window).resize(function () {
	clearTimeout(id);
	id = setTimeout(initialize, 500);
});
// initilize onload
initialize();